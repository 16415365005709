.health-fact {
    padding: 20px;
    background-color: #e5edf2;
    border: 1px solid #d1d9e0;
    border-radius: 8px;
    margin: 20px 0;
    text-align: center;
    h3 {
        font-size: 1.5rem;
        margin-bottom: 10px;
        color: #4aa3f1;
    }

    p {
        font-weight: 600;
    }
}
