.auth-container {
    width: 300px;
    margin: 0 auto;
    padding-top: 50px;
    text-align: center;
    margin-bottom: 70px;

    .auth-logo {
        width: 250px;
        height: auto;
        margin-bottom: 20px;
    }

    h2 {
        margin-bottom: 20px;
    }

    form {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    input {
        padding: 15px;
        border: 1px solid #ddd;
        border-radius: 4px;
    }

    button {
        padding: 15px;
        background-color: #007BFF;
        border: none;
        border-radius: 4px;
        color: #fff;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
            background-color: #0056b3;
        }
    }
}
