.featured {
    display: flex;
    justify-content: space-between;
    padding: 50px 0;
    margin-bottom: 25px;
    .video-card {
        flex: 1;
        margin: 0 15px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

        &:hover {
            transform: translateY(-10px);
        }

        img {
            width: 100%;
            height: 200px;
            object-fit: cover;
        }

        h3 {
            font-size: 1.5em;
            margin: 20px 10px;
        }
        
        p {
            color: #666;
            line-height: 1.5;
            margin: 0 20px 20px;
        }

        .video-thumb {
            position: relative;
            display: block;
        
            img {
                width: 100%;
                height: 200px;
                object-fit: cover;
                transition: opacity 0.3s; // added for a subtle hover effect
            }
        
            &:hover img {
                opacity: 0.7; // dim the image slightly on hover
            }
        
            .play-icon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%); // center the play button
                width: 50px;
                height: 50px;
                background: url('../assets/yt-icon.png') no-repeat center;
                background-size: contain;
                cursor: pointer; // indicate it's clickable
                opacity: 0.8; // making the play button slightly transparent
            }
        
            &:hover .play-icon {
                opacity: 1; // full opacity on hover
            }
        }
    }
    
}

h5 {
    margin-bottom: 5px;
    font-size: 20px;
    border-bottom: 3px solid #e74c3c;
    display: inline-block;
    padding-bottom: 10px;
}
