.video-item {
    width: 30%; 
    background-color: #f4f4f4;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    position: relative;
    margin-bottom: 20px;

    h3 {
        font-size: 1.2em;
        margin-top: 10px;
    }

    .thumbnail img {
        width: 100%;
        height: auto;
    }
    .thumbnail {
        position: relative;
        display: block;
    
        img {
            width: 100%;
            height: 200px;
            object-fit: cover;
            transition: opacity 0.3s; 
        }
    
        &:hover img {
            opacity: 0.7;
        }
    
        .play-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%); 
            width: 50px;
            height: 50px;
            background: url('../../assets/yt-icon.png') no-repeat center;
            background-size: contain;
            cursor: pointer; 
            opacity: 0.8;
        }
    
        &:hover .play-icon {
            opacity: 1;
        }
    }
}