.bmi-calculator {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px;
    background-color: #f6f8fa;
    border-radius: 8px;

    h2 {
        margin-bottom: 20px;
        color: #0077b6;
    }

    .bmi-content {
        display: flex;
        width: 100%;
        justify-content: space-between;

        .bmi-form, .bmi-info {
            flex: 1;
            padding: 20px;
            margin-left: 20px;
        }

        .bmi-form {
            border-right: 1px solid #e5edf2;

            label {
                display: block;
                margin-bottom: 15px;

                input {
                    display: block;
                    width: 100%;
                    padding: 8px 12px;
                    margin-top: 5px;
                    border-radius: 4px;
                    border: 1px solid #ccc;
                }
            }

            button {
                display: inline-block;
                padding: 10px 20px;
                background-color: #0077b6;
                color: #fff;
                border: none;
                border-radius: 4px;
                cursor: pointer;
                transition: background-color 0.3s ease;

                &:hover {
                    background-color: #005fa3;
                }
            }

            .bmi-result {
                margin-top: 20px;
                p {
                    font-size: 18px;
                    font-weight: bold;
                }
            }
        }

        .bmi-info {
            ul {
                list-style: none;
                padding-left: 0;

                li {
                    margin-bottom: 10px;
                    font-size: 16px;
                    color: #555;

                    span {
                        font-weight: bold;
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}
