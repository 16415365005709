.slider {
    position: relative;
    width: 100%;
    height: 700px;
    overflow: hidden;

    .slides-wrapper {
        display: flex;
        width: 100%;
        transition: transform 0.3s ease-out;
    }

    .slide {
        flex: 0 0 100%;
        position: relative;
        height: 700px;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;

        img {
            width: 100%;
            height: 400px;
            object-fit: cover;
        }

        .slide-content {
            position: absolute;
            bottom: 20px;
            left: 20px;
            color: #fff;

            h2 {
                font-size: 24px;
            }

            button {
                margin-top: 10px;
                padding: 10px 20px;
                background-color: #1586f0;
                border: none;
                border-radius: 5px;
                cursor: pointer;
                transition: background-color 0.3s;

                &:hover {
                    background-color: #3fadf7;
                }
            }
        }
    }

    .slider-btn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background: rgba(0, 0, 0, 0.5);
        color: #fff;
        border: none;
        padding: 10px 15px;
        cursor: pointer;
        transition: background-color 0.3s;

        &.prev {
            left: 10px;
        }

        &.next {
            right: 10px;
        }

        &:hover {
            background-color: #e74c3c;
        }
    }

    .slider-placeholder {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        color: white;
        text-align: center;
    }
}
