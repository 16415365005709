.hospital-sidebar {
    flex: 1;
    padding: 1rem;
    background: #f5f5f5;
    border-radius: 8px;

    .recent-posts {
        margin-top: 1rem;

        ul {
            list-style: none;
            padding: 0;

            li {
                margin-bottom: 1rem;
                
                p {
                    color: #777;
                }
            }
        }
    }

    .categories {
        margin-top: 1rem;
        border-top: 1px solid #eee;
        padding-top: 1rem;

        h4 {
            margin-bottom: 1rem;
        }

        ul {
            list-style: none;
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            gap: 0.5rem;

            li {
                background: #e5e5e5;
                padding: 0.25rem 0.5rem;
                border-radius: 8px;
                cursor: pointer;
                transition: background 0.2s;

                &:hover {
                    background: #d5d5d5;
                }
            }
            li.active {
                color: #333;
                background-color: #ffffff;
            }
        }
    }
}
