// .blog-sidebar {
//     padding: 20px;

//     .recent-posts {
//         margin-top: 40px;

//         h3 {
//             margin-bottom: 20px;
//         }

//         ul {
//             list-style-type: none;
//             padding-left: 0;

//             li {
//                 margin-bottom: 15px;

//                 a {
//                     text-decoration: none;
//                     color: #0096c7;
//                     transition: color 0.3s;

//                     &:hover {
//                         color: #0077b6;
//                     }
//                 }
//             }
//         }
//     }
// }


// .blog-sidebar {
//     width: 23%;
//     padding: 1rem;
//     box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

//     .recent-posts {
//         margin-top: 2rem;

//         li {
//             margin-bottom: 1rem;
//         }
//     }
// }


.blog-sidebar {
    flex: 1;
    padding: 1rem;
    background: #f5f5f5;
    border-radius: 8px;

    .recent-posts {
        margin-top: 1rem;

        ul {
            list-style: none;
            padding: 0;

            li {
                margin-bottom: 1rem;
                
                p {
                    color: #777;
                }
            }
        }
    }

    .categories {
        margin-top: 1rem;
        border-top: 1px solid #eee;
        padding-top: 1rem;

        h4 {
            margin-bottom: 1rem;
        }

        ul {
            list-style: none;
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            gap: 0.5rem;

            li {
                background: #e5e5e5;
                padding: 0.25rem 0.5rem;
                border-radius: 8px;
                cursor: pointer;
                transition: background 0.2s;

                &:hover {
                    background: #d5d5d5;
                }
            }
            li.active {
                color: #333;
                background-color: #ffffff;
            }
        }
    }
}
