.search-bar {
    display: flex;
    gap: 10px;

    input {
        flex: 1;
        padding: 10px;
    }

    button {
        padding: 10px 15px;
        background-color: #0096c7;
        color: #fff;
        border: none;
        cursor: pointer;

        &:hover {
            background-color: #0077b6;
        }
    }
}
