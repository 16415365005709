.contact-us {
    padding: 50px 5%;
    display: flex;
    justify-content: space-between;
    align-items: start;

    h2 {
        font-size: 2.2em;
        margin-bottom: 20px;
        color: #333;
    }

    input, textarea, button {
        display: block;
        width: 100%;
        margin-bottom: 40px;
        padding: 10px 15px;
        border: 1px solid #e5e5e5;
        border-radius: 5px;
        font-size: 1em;
        transition: border-color 0.3s;
        
        &:focus {
            border-color: #007BFF;
        }
    }

    textarea {
        resize: vertical;
        height: 150px;
    }

    button {
        background-color: #007BFF;
        color: #fff;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
            background-color: #0056b3;
        }
    }

    .contact-form-section {
        flex: 1;
        padding-right: 2%;
        box-shadow: 0 0 25px rgba(0,0,0,0.1);
        padding: 30px;
        border-radius: 10px;
    }

    .contact-info-section {
        flex: 1;
        padding-left: 2%;
        background-color: #f5f5f5;
        padding: 30px;
        border-radius: 10px;

        p {
            margin-bottom: 15px;
            font-size: 1.1em;
            color: #555;
        }
    }

    @media screen and (max-width: 768px) {
        flex-direction: column-reverse;

        .contact-form-section, .contact-info-section {
            width: 100%;
            padding-left: 0;
            padding-right: 0;
            margin-bottom: 20px;
        }
    }
}
/* ... previous styles ... */

.input-wrapper {
    position: relative;
    margin-bottom: 20px;

    input, textarea {
        width: 100%;
        padding: 10px;
        border: 1px solid #e5e5e5;
        border-radius: 5px;
        background-color: #f5f5f5;
        transition: all 0.3s;

        &:focus {
            border-color: #007BFF;
            background-color: #ffffff;

            + label {
                top: -20px;
                font-size: 0.8em;
                color: #007BFF;
            }
        }
    }

    label {
        position: absolute;
        left: 10px;
        top: 14px;
        transition: all 0.3s;
    }
}

.info-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    svg {
        width: 24px;
        height: 24px;
        margin-right: 10px;
    }
}
