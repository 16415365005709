.hero {
    background-color: #0077b6;
    color: #fff;
    padding: 100px 0;
    text-align: center;

    h1 {
        font-size: 2.5em;
        margin-bottom: 20px;
    }

    p {
        font-size: 1.2em;
        margin-bottom: 30px;
    }

    button {
        background-color: #00b4d8;
        border: none;
        padding: 10px 20px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: #0096c7;
        }
    }
}
