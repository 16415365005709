.aboutus-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 5%;
    text-align: justify;

    .aboutus-image {
        width: 100%;
        height: auto;
        max-width: 800px;
        border-radius: 8px;
        margin-bottom: 30px;
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    }

    .aboutus-content {
        width: 50%;
        h2 {
            font-size: 2.5em;
            margin-bottom: 25px;
            color: #333;
            font-weight: bold;
            border-bottom: 2px solid #ddd;
            padding-bottom: 20px;
        }

        p {
            margin-top: 20px;
            line-height: 1.7;
            color: #555;
            font-size: 1.1em;
            padding-right: 15px; // To make sure text doesn't touch the container edges
            padding-left: 15px; // To make sure text doesn't touch the container edges
        }
    }
}

@media screen and (max-width: 768px) {
    .aboutus-container {
        padding: 30px 2%;
    }

    .aboutus-content {
        h2 {
            font-size: 2em;
        }

        p {
            padding-right: 10px;
            padding-left: 10px;
        }
    }
}
