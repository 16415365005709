.featured {
    display: flex;
    justify-content: space-between;
    padding: 50px 0;
    margin-bottom: 25px;
    .article-card {
        flex: 1;
        margin: 0 15px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

        &:hover {
            transform: translateY(-10px);
        }

        img {
            width: 100%;
            height: 200px;
            object-fit: cover;
        }

        h3 {
            font-size: 1.5em;
            margin: 20px 10px;
        }
        
        p {
            color: #666;
            line-height: 1.5;
            margin: 0 20px 20px;
        }
    }
}

h5 {
    text-align: center;
    margin-bottom: 5px;
    font-size: 20px;
    border-bottom: 3px solid #e74c3c;
    display: inline-block;
    padding-bottom: 10px;
}