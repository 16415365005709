.blog-header {
    background-color: #f0eaea;
    padding: 40px 0;
    text-align: center;
    margin-bottom: 20px;
    h2 {
        font-size: 2.2em;
        margin-bottom: 20px;
    }

    p {
        font-size: 1.2em;
    }
}
