// .site-footer {
//     background-color: #0096c7;
//     color: #fff;
//     padding: 20px;
//     display: flex;
//     justify-content: space-between;
//     flex-wrap: wrap;
//     font-size: 14px;

//     .footer-section {
//         margin: 20px;
//         width: 20%; // Adjust based on preference

//         h4 {
//             margin-bottom: 10px;
//             font-size: 16px;
//         }

//         ul {
//             list-style-type: none;
//             padding: 0;
//             li a {
//                 color: #fff;
//                 text-decoration: none;
//                 &:hover {
//                     text-decoration: underline;
//                 }
//             }
//         }

//         .social-icons a {
//             display: inline-block;
//             margin-right: 10px;
//             width: 30px;
//             height: 30px;
//             background-size: contain;
//             background-repeat: no-repeat;

//             &.facebook {
//                 background-image: url('../assets/fb-icon.png');
//             }
//             &.twitter {
//                 background-image: url('../assets/twitter-icon.png');
//             }
//             &.instagram {
//                 background-image: url('../assets/inta-icon.png');
//             }
//             &.youtube {
//                 background-image: url('../assets/yt-icon.png');
//             }
//         }
//     }

//     .legal-section, .copyright-section {
//         margin: 20px;
//         width: 100%;
//         text-align: center;
//         a {
//             color: #fff;
//             text-decoration: none;
//             margin: 0 10px;
//             &:hover {
//                 text-decoration: underline;
//             }
//         }
//     }
// }



.site-footer {
    background-color: #0096c7;
    color: #ecf0f1;
    font-size: 16px;
    padding: 60px 20px;

    .footer-main {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 40px;

        .footer-section {
            flex: 1;
            min-width: 220px;
            margin: 20px;

            h4 {
                margin-bottom: 20px;
                font-size: 20px;
                border-bottom: 3px solid #e74c3c;
                display: inline-block;
                padding-bottom: 10px;
            }

            p, address {
                margin-bottom: 20px;
                line-height: 1.5;
            }

            ul {
                list-style-type: none;
                padding: 0;

                li a {
                    color: #bdc3c7;
                    text-decoration: none;
                    padding: 5px 0;
                    display: block;
                    &:hover {
                        color: #ecf0f1;
                        background-color: rgba(255, 255, 255, 0.1);;
                    }
                }
            }

            .social-icons a {
                display: inline-block;
                margin-right: 10px;
                width: 30px;
                height: 30px;
                background-size: contain;
                background-repeat: no-repeat;
                transition: opacity 0.3s;

                &:hover {
                    opacity: 0.7;
                }

                &.facebook {
                    background-image: url('../assets/fb-icon.png');
                }
                &.twitter {
                    background-image: url('../assets/twitter-icon.png');
                }
                &.instagram {
                    background-image: url('../assets/inta-icon.png');
                }
                &.youtube {
                    background-image: url('../assets/yt-icon.png');
                }
            }
        }
        
    }

    .footer-bottom {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #34495e;
        padding-top: 15px;

        a {
            color: #bdc3c7;
            text-decoration: none;
            margin: 0 10px;

            &:hover {
                color: #ecf0f1;
            }
        }
    }
}

// Add icons (you can use font-awesome or any other icon libraries for the purpose)
.address-icon, .phone-icon, .email-icon {
    margin-right: 8px;
}
