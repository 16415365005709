.hospital-item {
    width: 30%;
    background: #f5f5f5;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.2s;
    margin-bottom: 20px;

    &:hover {
        transform: translateY(-10px);
    }

    img {
        width: 100%;
        height: 200px;
        object-fit: cover;
    }

    h3 {
        font-size: 1.5em;
        margin: 20px 10px;
    }

    p {
        padding: 0.05rem 1rem;
    }
}
