.navbar {
    color: #fff;
    position: fixed;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;

    .navbar-top {
        background-color: #fff;  // New color for the top part
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 5%;
        height: 70px;
        color: black;
        
        .logo-section img {
            width: 150px; // Adjust based on your logo's size
        }

        .contact-info {
            display: flex;
            gap: 20px;

            .info-item {
                display: flex;
                align-items: center;
                gap: 5px;

                img {
                    width: 24px;  // Adjust size as needed
                }
            }
        }
    }

    .navbar-bottom {
        background-color: #0096c7;  // Original color for the bottom part
        padding: 0 5%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 90px;

        .nav-list {
            list-style-type: none;
            display: flex;
            gap: 20px;

            li {
                margin-right: 15px;
                font-size: 16px;

                cursor: pointer;
                transition: color 0.3s;

                &:hover {
                    color: #cfd8dc;
                }
            }

            .active-link {
                text-decoration: underline;
                font-weight: bold;
            }

            a {
                text-decoration: none;
                color: inherit;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
