.blog-list-page {
    display: flex;
    justify-content: space-between;
    margin: 2rem;

    .blogs {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 75%;
    }
}
