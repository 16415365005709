.blog-detail-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 50px 5%;
    background-color: #f7f9fc; 

    .blog-detail {
        width: 70%;
        margin-right: 3%;
        background: #f8f1f1;
        padding: 25px;
        border-radius: 8px;
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);

        h2 {
            font-size: 2.5em;
            margin-bottom: 20px;
            color: #333;
            font-weight: bold;
            border-bottom: 2px solid #ddd;
            padding-bottom: 15px;
        }

        img {
            width: 100%;
            height: auto;
            border-radius: 8px;
            margin: 25px 0;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        }

        p {
            margin-top: 15px;
            line-height: 1.7;
            color: #555;
            font-size: 1.1em;
            text-align: justify; 
        }
    }
}

