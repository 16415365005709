.video-detail-container {
    display: flex;
    justify-content: space-between;
    padding: 50px 5%;

    .video-detail-content {
        width: 70%;
        margin-right: 3%;
        background: #f3eeee;
        padding: 15px;
        border-radius: 8px;
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);

        iframe {
            width: 100%;
            height: 500px;
            border-radius: 8px;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
            margin-bottom: 20px;
        }

        h2 {
            font-size: 2em;
            margin-top: 15px;
            color: #333;
            font-weight: 600;
        }

        p {
            margin-top: 15px;
            line-height: 1.7;
            color: #555;
            font-size: 1.1em;
        }
    }
}
