.blog-page-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 1rem 5%;

    .blog-grid {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        width: 75%;
    }
}

