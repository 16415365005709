.content-wrapper {
    padding: 20px 5%;
    
    .blogs-section {
        display: flex;
        gap: 40px;

        .blog-list {
            flex: 2;
        }

        .blog-sidebar {
            flex: 1;
        }
    }
}
