// HospitalDetail.scss

.hospital-detail-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 50px 5%;
    background-color: #f7f9fc; 

    .hospital-detail {
        width: 70%;
        margin-right: 3%;
        background: #f8f1f1;
        padding: 25px;
        border-radius: 8px;
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);

        h2 {
            font-size: 2.5em;
            margin-bottom: 20px;
            color: #333;
            font-weight: bold;
            border-bottom: 2px solid #ddd;
            padding-bottom: 15px;
        }

        img {
            width: 100%;
            height: auto;
            border-radius: 8px;
            margin: 25px 0;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        }

        p {
            margin-top: 15px;
            line-height: 1.7;
            color: #555;
            font-size: 1.1em;
            text-align: justify; 
        }

        .services {
            display: flex;
            flex-wrap: wrap;
            gap: 15px;
            margin-top: 20px;

            .service-card {
                position: relative;
                flex-basis: calc(30.33% - 10px);
                height: 200px;
                background-size: cover;
                padding: 15px;
                border-radius: 8px;
                box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
                position: relative; 
                transition: transform 0.2s;
                
                &:hover {
                    transform: translateY(-10px);
                }
            }
            
            .service-card::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.5); /* Adjust opacity as needed */
              }
              .service-card h4,
              .service-card p {
                color: #fff; 
                position: relative;
                margin-top: 40px;
                text-align: center;
            }
        }
        .hospital-meta {
            display: flex;
            justify-content: space-between;
            align-items: start;  // Align vertically to the top
            gap: 15px;
            margin-top: 35px; 

            .hospital-info {
                flex: 1;
                margin-top: 25px;
                
                p {
                    font-weight: 600;
                    margin-bottom: 10px;
                }
            }

            .hospital-location {
                flex: 1.5;
                overflow: hidden;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .hospital-detail-wrapper {
        flex-direction: column;

        .hospital-detail {
            width: 100%;
            margin-right: 0;
        }
    }
}
